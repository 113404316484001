import React from "react"
import { Link, graphql } from "gatsby"
import { RiBugLine} from "react-icons/ri"
import { AiOutlineHome  } from "react-icons/ai";
import SEO from "../components/seo"
import Layout from "../components/layout"
import Img from "gatsby-image"

export const query = graphql`
  query {
    notFound: file(relativePath: { eq: "not_found.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const NotFound = ({ data }) => {
  return (
    <Layout className="not-found-page">
      <SEO title="Page Not Found" />
      <div
        className="wrapper"
        style={{
          textAlign: "center",
        }}
      >
        <header>
          <Img
            fluid={data.notFound.childImageSharp.fluid}
            alt="404 not found"
            objectFit="cover"
            objectPosition="50% 50%"
          />
          <h1>Oops we did not expect that to happen</h1>
          <p>
            Have you wondered into the unknow. Let us help you, Please take a
            look at below options
          </p>
        </header>
  
        <Link to="/" className="button-block">
          <AiOutlineHome  className="icon -left" />
          Back to Home
        </Link>
        <Link to="/contact" className="button-block -outline">
          Report this <RiBugLine className="icon -right" />
        </Link>
       
      </div>
    </Layout>
  )
}

export default NotFound
